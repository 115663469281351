const SUBSCRIPTION_BRAND_CONFIG: {
  [env: string]: {
    [shopBrand: string]: {
      [regionCode: string]: {
        [productBrandId: number | string]: {
          discountPercentage: {
            firstOrder: number;
            normal: number;
          };
        };
      };
    };
  };
} = {
  dev: {
    firstvet: {
      se: {
        general: {
          discountPercentage: {
            firstOrder: 0,
            normal: 0,
          },
        },
        // FirstVet
        179: {
          discountPercentage: {
            firstOrder: 50,
            normal: 10,
          },
        },
        // Hill's
        176: {
          discountPercentage: {
            firstOrder: 70,
            normal: 20,
          },
        },
      },
    },
    telus: {
      'ca-bc': {
        general: {
          discountPercentage: {
            firstOrder: 0,
            normal: 0,
          },
        },
      },
    },
  },
  prod: {
    firstvet: {
      se: {
        general: {
          discountPercentage: {
            firstOrder: 0,
            normal: 0,
          },
        },
        // FirstVet
        277: {
          discountPercentage: {
            firstOrder: 0,
            normal: 0,
          },
        },
      },
    },
    telus: {
      'ca-bc': {
        general: {
          discountPercentage: {
            firstOrder: 0,
            normal: 0,
          },
        },
      },
    },
  },
};

const getDevOrProd = (env: string): keyof typeof SUBSCRIPTION_BRAND_CONFIG =>
  env === 'prod' ? 'prod' : 'dev';

const getSubscriptionConfig = ({
  env,
  shopBrand,
  regionCode,
  productBrandId,
}: {
  env: string;
  shopBrand: ShopBrand;
  regionCode: RegionCode;
  productBrandId?: number;
}) => {
  const shopBrandSubscriptionConfig =
    SUBSCRIPTION_BRAND_CONFIG[getDevOrProd(env)]?.[shopBrand]?.[regionCode];

  if (productBrandId && shopBrandSubscriptionConfig?.[productBrandId]) {
    return shopBrandSubscriptionConfig[productBrandId];
  }

  return shopBrandSubscriptionConfig?.general;
};

export { SUBSCRIPTION_BRAND_CONFIG, getSubscriptionConfig };
